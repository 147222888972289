import http from "../http-common";

class EmployeeService {
  getAll(term, active, page, size) {
    const params = {
      sort: "name,asc",
      term: encodeURI(term),
    };
    const url = "/employee/search";

    if (active) {
      params["active"] = active;
    }
    if (page) {
      params["page"] = page;
    }
    if (size) {
      params["size"] = size;
    }
    return http.get(url, { params });
  }

  get(id) {
    return http.get(`/employee/${id}`);
  }

  getByEmail(email) {
    return http.get(`/employee/findByEmail`, {
      params: {
        email,
        view: "full",
      },
    });
  }

  create(data) {
    return http.post("/employee", data);
  }

  update(id, data) {
    return http.patch(`/employee/${id}`, data);
  }

  save(data) {
    return data.id ? this.update(data.id, data) : this.create(data);
  }

  delete(id) {
    return http.delete(`/employee/${id}`);
  }
}

export default new EmployeeService();
