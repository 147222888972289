import axios from "axios";

let axiosInstance = axios.create({
  baseURL: "/api",
  headers: {
    "content-type": "application/json",
  },
});

axiosInstance.interceptors.request.use((config) => {
  let authorizationHeader = `Bearer ${
    JSON.parse(sessionStorage["okta-token-storage"] || "{}")?.accessToken
      ?.accessToken
  }`;
  config.headers.common["authorization"] = authorizationHeader;
  return config;
});

export default axiosInstance;
