import { reactive, toRefs } from "vue";

import EmployeeService from "@/services/EmployeeService";

const state = reactive({
  employee: null,
});

export default function useEmployee(email) {
  if (email && !state.employee?.email) {
    EmployeeService.getByEmail(email)
      .then((response) => (state.employee = response.data))
      .catch((e) => console.error(e));
  }

  const logout = () => (state.employee = null);

  return {
    ...toRefs(state),
    logout,
  };
}
