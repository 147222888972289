import { reactive, toRef } from "vue";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";

const connections = reactive({});
const callbacks = reactive({});

export default function useEventBus({
  id,
  callback = () => {},
  queue = "/topic/burrow-alerts",
  uri = "/events/burrow",
}) {
  if (!(queue in connections)) {
    connections[queue] = false;
    callbacks[queue] = {};

    let stompClient = null;

    const connect = () => {
      const socket = new SockJS(uri);
      stompClient = Stomp.over(socket);
      stompClient.debug = (f) => f;

      stompClient.connect(
        {},
        () => {
          connections[queue] = true;
          stompClient.subscribe(queue, (message) => {
            const body = JSON.parse(message.body);
            for (const [, value] of Object.entries(callbacks[queue])) {
              value(body);
            }
          });
        },
        () => {
          connections[queue] = false;
          console.warn("Lost connection, retrying...");
          setTimeout(connect, 1000);
        }
      );
    };

    // Connect immediately.
    connect();
  }

  if (callback) {
    callbacks[queue][id] = callback;
  }

  const connected = toRef(connections, queue);

  const unsubscribe = () => {
    delete callbacks[queue][id];
  };

  return {
    connected,
    unsubscribe,
  };
}
