<template>
  <nav
    class="navbar navbar-expand-lg navbar-dark fixed-top"
    :class="{
      'bg-dark': isDarkMode,
      'bg-primary': !isDarkMode,
    }"
  >
    <div class="container-lg">
      <router-link to="/" class="navbar-brand">
        <img
          src="../assets/logo-sign.svg"
          alt="Burrow"
          class="logo align-top"
        />
        Burrow
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#burrow-navbar"
        aria-controls="burrow-navbar"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="burrow-navbar">
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <router-link
              :to="{ name: 'browse-schedules' }"
              v-if="authenticationState && hasView"
            >
              <button class="btn btn-sm me-1 nav-link">
                <font-awesome-icon icon="calendar-alt" />
                Calendars
              </button>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/sites"
              v-if="authenticationState && hasAdmin"
              class="text-light text-decoration-none"
            >
              <button class="btn btn-sm me-1 nav-link">
                <font-awesome-icon icon="building" />
                Sites
              </button>
            </router-link>
          </li>
          <li class="nav-item">
            <a
              href="https://animallogic.atlassian.net/wiki/spaces/DevOps/pages/14982006363630/Burrow+-+User+Guide"
              target="burrow-help"
            >
              <button class="btn btn-sm me-1 nav-link">
                <font-awesome-icon icon="question-circle" />
                Help
              </button>
            </a>
          </li>
        </ul>
        <ul class="navbar-nav">
          <li>
            <datepicker
              v-model="startDate"
              v-if="hasView"
              :enable-time-picker="false"
              :auto-apply="true"
              :close-on-auto-apply="true"
              :clearable="false"
              format="d MMM yyyy"
              class="pt-1 pe-3"
            >
              <template #dp-input="{ value }">
                <div class="input-group input-group-sm">
                  <span class="input-group-text">
                    <font-awesome-icon icon="calendar-alt" />
                  </span>
                  <input type="text" class="form-control" :value="value" />
                  <span
                    class="input-group-text"
                    @click.stop="incrementWeek(-1)"
                  >
                    <font-awesome-icon icon="chevron-left" />
                  </span>
                  <span class="input-group-text" @click.stop="incrementWeek(1)">
                    <font-awesome-icon icon="chevron-right" />
                  </span>
                </div>
              </template>
            </datepicker>
          </li>
          <li class="nav-item dropdown" v-if="authenticationState && hasView">
            <a
              class="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              href="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <font-awesome-icon v-if="isDarkMode" icon="moon" />
              <font-awesome-icon v-else icon="sun" />
            </a>
            <div class="dropdown-menu">
              <a
                href="#"
                class="dropdown-item"
                @click="nextTheme"
                title="Cycle through colour schemes, some of which are optimised for various forms of colour blindness. Your choice will be stored in your browser and restored between sessions (though you will have re-select your preferred theme on each separate device you use)."
              >
                <font-awesome-icon icon="eye" />
                Bubble Colours
              </a>
              <div class="dropdown-divider" />
              <a
                v-for="(theme, name) in bootswatchThemes"
                :key="name"
                class="dropdown-item"
                :class="{ 'text-success': bootswatchTheme === name }"
                href="#"
                @click="setBootswatchTheme(name)"
              >
                <font-awesome-icon v-if="theme['isDark']" icon="moon" />
                <font-awesome-icon v-else icon="sun" />
                {{ theme["label"] }}
              </a>
            </div>
          </li>
          <li
            class="nav-item my-2"
            v-if="authenticationState"
            :title="user?.email"
          >
            <router-link
              v-if="employee?.id"
              title="Edit my schedule."
              :to="{
                name: 'edit-schedule',
                params: { email: employee?.email },
              }"
              class="nav-link py-0"
            >
              <font-awesome-icon
                v-if="hasAdmin"
                icon="user-ninja"
                title="Your account has administrative permission. Be careful!"
              />
              <font-awesome-icon
                v-else-if="hasEdit"
                icon="user-edit"
                title="Your account has edit permission."
              />
              <font-awesome-icon
                v-else-if="hasView"
                icon="user"
                title="Your account has view-only permission."
              />
              {{ user?.name }}
            </router-link>
            <span v-else class="ms-2 opacity-50">
              <font-awesome-icon
                icon="user-slash"
                title="You are logged in via Okta, but you aren't setup in Burrow."
              />
              {{ user?.name }}
            </span>
          </li>
          <li class="nav-item">
            <router-link
              to="/logout"
              v-if="isAuthenticated"
              class="text-light text-decoration-none"
            >
              <button class="btn btn-sm nav-link">
                <font-awesome-icon icon="sign-out-alt" />
                Logout
              </button>
            </router-link>
            <router-link
              to="/login"
              v-else
              class="text-light text-decoration-none"
            >
              <button class="btn btn-sm nav-link">
                <font-awesome-icon icon="sign-in-alt" />
                Log In
              </button>
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <div
      v-if="connected"
      class="navbar-text me-2 text-success position-absolute websocket-lamp"
      title="Live refresh server connected."
    >
      <font-awesome-icon icon="check-circle" />
    </div>
    <div
      v-if="!connected"
      class="navbar-text me-2 text-warning position-absolute websocket-lamp"
      title="Live refresh server disconnected."
    >
      <font-awesome-icon icon="times-circle" />
    </div>
  </nav>
</template>

<script>
import { ref, computed, inject } from "vue";
import { useRouter } from "vue-router";
import Datepicker from "@vuepic/vue-datepicker";

import useEventBus from "../module/event-bus";
import useUser from "../module/user";
import useDateRange from "../module/date-range";
import useEmployee from "../module/current-employee";
import useThemeSwitcher from "@/module/themeSwitcher";

export default {
  name: "NavBar",
  components: {
    Datepicker,
  },
  setup() {
    const luxon = inject("luxon");

    const router = useRouter();

    const bootswatchThemes = {
      cosmo: {
        label: "Cosmo",
        isDark: false,
      },
      flatly: {
        label: "Flatly",
        isDark: false,
      },
      minty: {
        label: "Minty",
        isDark: false,
      },
      pulse: {
        label: "Pulse",
        isDark: false,
      },
      sandstone: {
        label: "Sandstone",
        isDark: false,
      },
      cyborg: {
        label: "Cyborg",
        isDark: true,
      },
      darkly: {
        label: "Darkly",
        isDark: true,
      },
      superhero: {
        label: "Superhero",
        isDark: true,
      },
    };

    const bootswatchTheme = ref(localStorage.getItem("theme") || "cosmo");

    const setBootswatchTheme = (newTheme) => {
      // bootswatchTheme.value = newTheme;
      localStorage.setItem("theme", newTheme);
      router.go(0);
    };

    const nextBootswatchTheme = () =>
      setBootswatchTheme(
        Object.keys(bootswatchThemes)[
          (Object.keys(bootswatchThemes).indexOf(bootswatchTheme.value) + 1) %
            bootswatchThemes.length
        ]
      );
    const isDarkMode = computed(
      () => bootswatchThemes[bootswatchTheme.value]?.isDark
    );

    const { startDate, incrementWeek } = useDateRange({});
    const { connected } = useEventBus({});

    const { employee } = useEmployee();

    const { authenticationState, user, groups } = useUser();

    const hasAdmin = computed(() => groups.value?.indexOf("burrow_admin") >= 0);
    const hasEdit = computed(() => groups.value?.indexOf("burrow_edit") >= 0);
    const hasView = computed(() => groups.value?.indexOf("burrow_view") >= 0);

    const isAuthenticated = computed(() => !!authenticationState?.value);

    const { nextTheme } = useThemeSwitcher();

    return {
      // Data
      connected,
      authenticationState,
      user,
      startDate,
      employee,
      bootswatchTheme,
      bootswatchThemes,

      // Computed
      hasAdmin,
      hasEdit,
      hasView,
      isAuthenticated,

      // Methods
      incrementWeek,
      nextTheme,
      nextBootswatchTheme,
      isDarkMode,
      setBootswatchTheme,

      // Libraries
      luxon,
    };
  },
};
</script>

<style scoped lang="scss">
.logo {
  width: 40px;
}

.websocket-lamp {
  top: -3px;
  right: 0;
  z-index: 1;
}

.dp__main .input-group-text {
  z-index: 10;
}
</style>
