import { inject, reactive, toRefs } from "vue";
import useEmployee from "@/module/current-employee";

const state = reactive({
  user: null,
  groups: null,
  authenticationState: null,
});

export default function useUser(onAuthenticated) {
  const oktaAuth = inject("oktaAuth");
  if (!state.authenticationState) {
    oktaAuth.authStateManager.subscribe(async (authState) => {
      if (await oktaAuth.isAuthenticated()) {
        state.authenticationState = authState;
        state.user = authState?.idToken?.claims;
        state.groups = authState?.accessToken?.claims?.groups;
        if (onAuthenticated) {
          onAuthenticated(state.authenticationState);
        }
      }
    });
  } else {
    if (onAuthenticated) {
      onAuthenticated(state.authenticationState);
    }
  }

  const logout = async () => {
    state.user = null;
    state.groups = null;
    state.authenticationState = null;
    const { logout } = useEmployee();
    logout();
    await oktaAuth.signOut().catch(() => {});
  };

  return {
    ...toRefs(state),
    logout,
  };
}
