import { createApp } from "vue";
import { OktaAuth } from "@okta/okta-auth-js";
import OktaVue from "@okta/okta-vue";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircle as farCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faAngleDoubleLeft as fasAngleDoubleLeft,
  faAngleDoubleRight as fasAngleDoubleRight,
  faAngleLeft as fasAngleLeft,
  faAngleRight as fasAngleRight,
  faBan as fasBan,
  faBars as fasBars,
  faBug as fasBug,
  faBuilding as fasBuilding,
  faCalendarAlt as fasCalendarAlt,
  faCheck as fasCheck,
  faCheckCircle as fasCheckCircle,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faDiceFive as fasDiceFive,
  faDownload as fasDownload,
  faExchangeAlt as fasExchangeAlt,
  faEye as fasEye,
  faFileExcel as fasFileExcel,
  faInfoCircle as fasInfoCircle,
  faLock as fasLock,
  faMinus as fasMinus,
  faMinusSquare as fasMinusSquare,
  faMoon as fasMoon,
  faPencilAlt as fasPencilAlt,
  faPiggyBank as fasPiggyBank,
  faPlus as fasPlus,
  faPlusSquare as fasPlusSquare,
  faQuestion as fasQuestion,
  faQuestionCircle as fasQuestionCircle,
  faSave as fasSave,
  faSearch as fasSearch,
  faSignInAlt as fasSignInAlt,
  faSignOutAlt as fasSignOutAlt,
  faSun as fasSun,
  faSyncAlt as fasSyncAlt,
  faThList as fasThList,
  faTimes as fasTimes,
  faTimesCircle as fasTimesCircle,
  faTrashAlt as fasTrashAlt,
  faUser as fasUser,
  faUserEdit as fasUserEdit,
  faUserNinja as fasUserNinja,
  faUserSlash as fasUserSlash,
  faUsers as fasUsers,
} from "@fortawesome/free-solid-svg-icons";
import App from "./App.vue";
import router from "./router";
import "bootstrap/dist/js/bootstrap.js";

library.add(
  ...[
    farCircle,
    fasAngleDoubleLeft,
    fasAngleDoubleRight,
    fasAngleLeft,
    fasAngleRight,
    fasBan,
    fasBars,
    fasBug,
    fasBuilding,
    fasCalendarAlt,
    fasCheck,
    fasCheckCircle,
    fasChevronLeft,
    fasChevronRight,
    fasDiceFive,
    fasDownload,
    fasExchangeAlt,
    fasEye,
    fasFileExcel,
    fasInfoCircle,
    fasLock,
    fasMinus,
    fasMinusSquare,
    fasMoon,
    fasPencilAlt,
    fasPiggyBank,
    fasPlus,
    fasPlusSquare,
    fasQuestion,
    fasQuestionCircle,
    fasSave,
    fasSearch,
    fasSignInAlt,
    fasSignOutAlt,
    fasSun,
    fasSyncAlt,
    fasThList,
    fasTimes,
    fasTimesCircle,
    fasTrashAlt,
    fasUser,
    fasUserEdit,
    fasUserNinja,
    fasUserSlash,
    fasUsers,
  ]
);

// Bootswatch
const themeName = localStorage.getItem("theme") || "cosmo";
import("bootswatch/dist/" + themeName + "/bootstrap.min.css");
import "bootstrap";
import "bootstrap/dist/js/bootstrap.esm.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@popperjs/core";

import "./styles/ui.scss";

import("./styles/theme." + themeName + ".scss");

// Vue Notification
import VueNotificationList from "@dafcoe/vue-notification";
import "@dafcoe/vue-notification/dist/vue-notification.css";

// Luxon
import { DateTime } from "luxon";

// Vue3 Date Time Picker
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

// Configure Okta
const oktaAuth = new OktaAuth({
  issuer: "https://animallogic.okta.com/oauth2/ausefn3u1aSbuxETx2p7",
  clientId: "0oaefolnrfucVLStc2p7",
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: ["openid", "profile", "email", "offline_access", "burrow"],

  tokenManager: {
    storage: "sessionStorage",
    autoRenew: true,
  },

  transformAuthState: async (oktaAuth, authState) => {
    localStorage.accessToken = authState.accessToken.value;
    return authState;
  },
});

// Floating Vue - tooltip helper
import {
  // Directives
  VTooltip,

  // Components
  Dropdown,
} from "floating-vue";
import "floating-vue/dist/style.css";

const app = createApp(App)
  .use(router)
  .use(OktaVue, { oktaAuth }) //
  .use(VueNotificationList)
  .provide("luxon", DateTime)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("datepicker", Datepicker)
  .directive("tooltip", VTooltip)
  .component("VDropdown", Dropdown);
app.provide("oktaAuth", app.config.globalProperties.$auth);

app.mount("#app");
