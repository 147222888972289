import { reactive, toRefs } from "vue";

const numThemes = 5;

const THEME_STORAGE_KEY = "burrow-theme";
const state = reactive({
  selectedTheme: parseInt(localStorage.getItem(THEME_STORAGE_KEY)) || 0,
});

export default function useThemeSwitcher() {
  const nextTheme = () => {
    state.selectedTheme = ((state.selectedTheme || 0) + 1) % numThemes;
    localStorage.setItem(THEME_STORAGE_KEY, state.selectedTheme.toString(10));
  };
  return { ...toRefs(state), nextTheme };
}
