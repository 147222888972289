import { ref, computed } from "vue";
import { DateTime } from "luxon";

const startDate = ref(null);
const numDays = ref(21);

export default function useDateRange({ date, days, timezone }) {
  if (date) {
    startDate.value = DateTime.fromISO(date);
  } else if (!startDate.value) {
    startDate.value = DateTime.now() //
      .setZone(timezone || "utc")
      .startOf("week")
      .minus({ weeks: 1 });
  }
  if (days) {
    numDays.value = days;
  }

  const incrementWeek = (distance = 1) => {
    startDate.value = startDate.value.plus({ weeks: distance });
    return startDate;
  };

  const startDateC = computed({
    get: () => startDate.value,
    set: (x) => {
      startDate.value = DateTime.fromJSDate(x) //
        .startOf("week")
        .setZone(timezone || "utc");
    },
  });

  return {
    // Data
    startDate: startDateC,
    numDays,

    // Methods
    incrementWeek,
  };
}
