import { createRouter, createWebHistory } from "vue-router";
import { navigationGuard } from "@okta/okta-vue";
import CustomLoginCallback from "../components/CustomLoginCallback";
import CustomLogout from "@/components/CustomLogout";
import Home from "../components/Home";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },

  {
    path: "/employees",
    name: "employees",
    component: () => import("@/components/employee/EmployeeList"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/employees/:id",
    name: "edit-employee",
    component: () => import("@/components/employee/EmployeeForm"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/employees",
    name: "add-employee",
    component: () => import("@/components/employee/EmployeeForm"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/employees/:id/delete",
    name: "delete-employee",
    component: () => import("@/components/employee/EmployeeDelete"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/sites",
    name: "sites",
    component: () => import("@/components/site/SiteList"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sites/:id",
    name: "edit-site",
    component: () => import("@/components/site/SiteForm"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sites",
    name: "add-site",
    component: () => import("@/components/site/SiteForm"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sites/:id/delete",
    name: "delete-site",
    component: () => import("@/components/site/SiteDelete"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/schedule/:email",
    name: "edit-schedule",
    component: () => import("@/components/schedule/ScheduleEditor"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/browse",
    name: "browse-schedules",
    component: () => import("@/components/browse/BrowseEmployeeSchedules"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/junk",
    name: "junk",
    component: () => import("@/components/JunkThing"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/login",
    name: "login",
    component: {
      name: "Login",
      created() {
        this.login();
      },
      methods: {
        async login() {
          await this.$auth.signInWithRedirect();
        },
      },
    },
  },
  {
    path: "/login/callback",
    name: "login-callback",
    component: CustomLoginCallback,
  },
  {
    path: "/logout",
    name: "Logout",
    component: CustomLogout,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(navigationGuard);

export default router;
