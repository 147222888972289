<template>
  <vue-notification-list position="bottom-right" />
  <div class="container">
    <nav-bar />
    <router-view />
  </div>
</template>

<script>
import NavBar from "./components/NavBar";
import useEmployee from "@/module/current-employee";
import useUser from "@/module/user";

export default {
  name: "App",
  components: {
    NavBar,
  },
  setup() {
    // When we get authenticated, load the corresponding employee record.
    const onAuthenticated = async (authState) => {
      useEmployee(authState.idToken.claims.email);
    };
    useUser(onAuthenticated);
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
