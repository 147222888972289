<template>
  <div class="container pt-3" v-if="employee">
    <h1 class="display-4">Welcome to Burrow!</h1>
    <p>
      Burrow allows you to nominate your own Work From Home schedules, including
      both your ongoing regular routine, and any ad-hoc variations.
    </p>
    <p>
      You can also view the arrangements of your teammates, so that you know
      whether they'll be available for meetings in-person or remotely on Teams.
    </p>
    <p>
      Burrow imports <b>approved</b> Leave arrangements from Ceridian Dayforce,
      so you can view that information here as well. You cannot edit your leave
      directly within Burrow, but Ceridian updates should appear in Burrow
      automatically within 24 hours of approval.
    </p>
    <p>
      If you are looking to modify your own Work from Home arrangements, click
      <router-link
        v-if="employee?.id"
        title="Edit my schedule."
        :to="{ name: 'edit-schedule', params: { email: employee?.email } }"
        >here</router-link
      >.
    </p>
    <div class="alert alert-success d-flex align-items-top">
      <font-awesome-icon icon="info-circle" class="mt-1 me-2" />
      <span>
        You can also click your own name in the toolbar at the top, to visit
        your own schedule at any time.
      </span>
    </div>
    <p>
      If you are looking to view the arrangements of your teammates, click
      <router-link :to="{ name: 'browse-schedules' }">here</router-link>.
    </p>
    <div class="alert alert-primary d-flex align-items-top">
      <font-awesome-icon icon="question-circle" class="mt-1 me-2" />
      <div>
        <p class="fw-bold">How else can I view my team's availability?</p>
        <p>
          The
          <router-link :to="{ name: 'browse-schedules' }">
            <button class="btn btn-primary">
              <font-awesome-icon icon="calendar-alt" />
              Calendars
            </button>
          </router-link>
          tab allows you to view calendar information for your team and any
          other Animal Logic crew.
        </p>
      </div>
    </div>
    <div class="alert alert-success d-flex align-items-top">
      <font-awesome-icon icon="info-circle" class="mt-1 me-2" />
      <div>
        Use Burrow's powerful <font-awesome-icon icon="search" /> search system
        (on the Calendars tab) to narrow your view down to exactly the crew you
        are interested in, based on crew names, departments, job titles etc.
        Then use the
        <font-awesome-icon icon="bars" />
        Search Preset menu to save as many presets as you like, to recall even
        the most complex searches for future use.
      </div>
    </div>
  </div>
  <div class="container pt-3" v-else>
    <h2 class="display-2">Welcome to Burrow!</h2>
    <p>
      Please
      <router-link to="/login" class="text-light text-decoration-none">
        <button class="btn btn-sm btn-primary">
          <font-awesome-icon icon="sign-in-alt" />
          Log In
        </button>
      </router-link>
      to continue.
    </p>
  </div>
</template>

<script>
import useEmployee from "../module/current-employee";

export default {
  name: "HomeScreen",
  setup() {
    const { employee } = useEmployee();

    return {
      employee,
    };
  },
};
</script>
