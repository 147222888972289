<template>
  <div></div>
</template>

<script>
import { onMounted } from "vue";
import useUser from "@/module/user";
import { useRouter } from "vue-router";

export default {
  name: "CustomLogout",
  setup() {
    const router = useRouter();

    onMounted(() => {
      const { logout } = useUser();
      return logout().then(() => {
        router.push("/");
      });
    });

    return {};
  },
};
</script>
