<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CustomLoginCallback",
  data() {
    return {
      error: null,
    };
  },
  async beforeMount() {
    const router = this.$router;
    try {
      await this.$auth.handleLoginRedirect().then(async () => {
        router.replace("/");
      });
    } catch (e) {
      if (this.$auth.isInteractionRequiredError(e)) {
        const { onAuthResume, onAuthRequired } = this.$auth.options;
        const callbackFn = onAuthResume || onAuthRequired;
        if (callbackFn) {
          callbackFn(this.$auth);
          return;
        }
      }
      this.error = e.toString();
    }
  },
  render() {
    return <h1>{this.error}</h1>;
  },
});
</script>
